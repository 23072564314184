import React from 'react';
import LastCheckedContext from '../LastCheckedContext';

export default class IndeterminateCheckbox extends React.Component {
  componentDidMount() {
    this.el.indeterminate = this.props.indeterminate;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.indeterminate !== this.props.indeterminate) {
      this.el.indeterminate = this.props.indeterminate;
    }
  }

  render() {
    const { indeterminate, ...rest } = this.props;
    return (
      <LastCheckedContext.Consumer>
        {(value) => (
        <input {...rest} type="checkbox" onClick={(ev) => this.shiftClick(ev, value)} ref={el => this.el = el} />
        )}
      </LastCheckedContext.Consumer>
    );
  }

  shiftClick(event, context){
    let clicked = this.el;
    let lastChecked = context["lastChecked"];
    if(lastChecked === null){
      context["updateLastChecked"](clicked);
    } 
    else {
      if(event.shiftKey){
        let siblings = lastChecked.closest("ul").querySelectorAll(".form-check-input");
        if(Array.prototype.includes.call(siblings, clicked) && siblings.length > 2) {
          let thisIndex = Array.prototype.indexOf.call(siblings, clicked);
          let previousIndex = Array.prototype.indexOf.call(siblings, lastChecked);
          let targets = Array.prototype.slice.call(siblings, Math.min(thisIndex, previousIndex), Math.max(thisIndex, previousIndex) +1);
          let isParent = clicked.id.includes("Group") || clicked.id.includes("all")
          Array.prototype.forEach.call(targets, function (checkbox) {
            let checkboxParent = checkbox.id.includes("Group") || checkbox.id.includes("all")
            if(checkbox.checked != clicked.checked && checkboxParent === isParent) {
              checkbox.click();
            }
          });
        }
      }
      context["updateLastChecked"](clicked);
    }
  }
}

IndeterminateCheckbox.defaultProps = {
  indeterminate: false,
};
