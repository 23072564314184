import React from 'react';
import Component from "./Component";
import { getAndDenormalise } from "../../utils";
import Loading from "../../Loading";

const AssetContainerVisualiser = ({ componentID, preselect }) => {
  const [ component, setComponent ] = React.useState(null);
  const [ isLoading, setLoading ] = React.useState(true);

  React.useEffect(
    () => {
      setLoading(true);
      getAndDenormalise(
        `/api/v2/components/${componentID}?include=asset_containers`
      ).then(
        component => {
          setComponent(component);
          setLoading(false);
        }
      );
    },
    [componentID]
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Component
      component={component}
      preselect={preselect}
    />
  );
}

export default AssetContainerVisualiser;
