import React from 'react';
import LastCheckedContext from './LastCheckedContext';

export class LastCheckedProvider extends React.Component {
  state = {
    lastChecked: null
  };

  render(){
    return (
      <LastCheckedContext.Provider value={{
        lastChecked: this.state.lastChecked,
        updateLastChecked: newLastChecked => {
          this.setState({
            lastChecked: newLastChecked
          });
        }
      }
    }
    >
    {this.props.children}
    </LastCheckedContext.Provider>
    );
  }
}
