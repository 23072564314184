import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import MarkdownField from "./MarkdownField";
import EventWindowsForm from "./EventWindowsForm";
import MetadataForm from "./MetadataForm";
import RailsFormMeta from "./RailsFormMeta";
import { boundWindowsToEvent } from "../constraints";

const Subtitle = ({ kase }) => {
  if (!kase) {
    return null;
  }

  return (
    <Fragment>
      <span> for </span>
      <a href={kase['url']}>{kase['display_id']}</a>
    </Fragment>
  )
};

export default ({ actionName, availableTypes, availableCases, event, relatedCase, stateEvent, target, windows }) => {

  const [statefulEvent, setStatefulEvent] = React.useState(event);
  const [eventWindows, setWindows] = React.useState(windows);

  const durationTotalMins = statefulEvent.duration / 60;
  const durationHours = Math.floor(durationTotalMins / 60);
  const durationMins = durationTotalMins % 60;

  const updateEvent = (attrs) => {
    const newEvent = { ...statefulEvent, ...attrs };
    setStatefulEvent(newEvent);
    if (attrs['duration'] || attrs['expiry_range']) {
      setWindows(boundWindowsToEvent(eventWindows, newEvent));
    }
  };

  const onSubmit = (event) => {
    const form = document.getElementById('event-form');
    if (!form.reportValidity()) {
      form.preventDefault();
    }
    if(statefulEvent.case_id === '' && document.body.classList.contains('admin')) {
      let submit = confirm("Are you sure you want to create an event without an existing case? " + 
                           "This will create a new site-level case.");
      if(submit === true) {
        form.submit();
      } 
    } else {
      form.submit();
    }
  }

  React.useEffect(
    () => {
      if (statefulEvent.case_id === null) {
        updateEvent({case_id: ''});
      }
    }
  );

  return (
    <Fragment>
      {
        (actionName === 'new') &&
        ReactDOM.createPortal(
          <div>
            New event
            <Subtitle kase={availableCases[statefulEvent.case_id]} />
          </div>,
          document.getElementById('subtitle')
        )
      }
      <Form
        id='event-form'
        action={target}
        method='post'
      >
        <RailsFormMeta />
        <Container fluid>
          <Row>
            <Col xs={12} md={4}>
              <FormGroup>
                <Label for='event-name'>Event name</Label>
                <Input
                  id='event-name'
                  onChange={(evt) => updateEvent({name: evt.target.value})}
                  name='event[name]'
                  required
                  value={statefulEvent.name || ''}
                />
              </FormGroup>
              <FormGroup>
                <Label for='event-type'>Type of event</Label>
                <Input
                  id='event-type'
                  onChange={(evt) => updateEvent({type: evt.target.value})}
                  name='event[type]'
                  type='select'
                  value={statefulEvent.type}
                >
                  {
                    Object.entries(availableTypes).map(
                      ([t, spec], idx) => (
                        <option key={idx} value={t}>{spec.name}</option>
                      )
                    )
                  }
                </Input>
              </FormGroup>
              { actionName === 'new' &&
                document.body.classList.contains('admin') &&
                <FormGroup>
                  <Label for="event-case-dropdown">
                    Attach to case
                    <small className="form-text text-muted">
                      Choose a case to attach this new event to.
                    </small>
                  </Label>
                  <Input
                    id='event-case-dropdown'
                    onChange={ (evt) => updateEvent({case_id: evt.target.value})}
                    name='event[case_id]'
                    type='select'
                    value={statefulEvent.case_id || ''}
                    disabled={actionName === 'edit'}
                  >
                    <option value=''>Create a new case</option>
                    {
                      Object.entries(availableCases).map(
                        ([c, spec], idx) => (
                          <option key={idx} value={c}>{spec.display_id} - {spec.name}</option>
                        )
                      )
                    }
                  </Input>
                </FormGroup>
              }
              { !(document.body.classList.contains('admin')) &&
                  <Input type='hidden' name='event[case_id]' value={relatedCase} />
              }
              <FormGroup>
                <Label for="event-description">
                  Description
                  <small className="form-text text-muted">
                    Describe your event in as much detail as possible, including your
                    desired outcomes.
                  </small>
                </Label>
                <MarkdownField
                  value={statefulEvent.description || ''}
                  onChange={(evt) => updateEvent({description: evt.target.value})}
                  options={{
                    id: 'event-description',
                    name: 'event[description]',
                    rows: 5,
                    required: true
                  }}
                  siteID={statefulEvent['site_id']}
                />
              </FormGroup>
              <MetadataForm
                event={statefulEvent}
                spec={(availableTypes[statefulEvent.type] || {}).metadata || []}
              />
              <FormGroup>
                <label>Event duration</label>
                <div className="form-inline">
                  <label>
                    <Input
                      className='event-duration-control mr-1'
                      max={999}
                      min={0}
                      name='event[duration_time][hours]'
                      onChange={(evt) => updateEvent({ duration: ((60 * parseInt(evt.target.value || 0, 10)) + durationMins) * 60 })}
                      type='number'
                      value={durationHours}
                    />
                    hours
                  </label>
                  <label>
                    <Input
                      className='event-duration-control mx-1'
                      max={59}
                      min={0}
                      name='event[duration_time][minutes]'
                      onChange={(evt) => updateEvent({ duration: ((60 * durationHours) + parseInt(evt.target.value || 0, 10)) * 60 } )}
                      type='number'
                      value={durationMins}
                    />
                    minutes
                  </label>
                </div>
              </FormGroup>
              <FormGroup>
                <label>
                  Required notice period
                </label>
                <div className="form-inline">
                  <label>
                    <Input
                      className='mr-1'
                      min={0}
                      name='event[expiry_range]'
                      onChange={(evt) => updateEvent({expiry_range: evt.target.value})}
                      type='number'
                      value={statefulEvent['expiry_range']}
                    />
                    business days
                  </label>
                  <small className="form-text text-muted mb-1">
                    The recipient will not be able to schedule an event in an
                    availability window unless there is at least this much time
                    before the start of the event.
                  </small>
                </div>
              </FormGroup>
            </Col>
            <Col xs={12} md={8}>
              <EventWindowsForm
                event={statefulEvent}
                setWindows={setWindows}
                windows={eventWindows}
              />
            </Col>
          </Row>
        </Container>
        {
          stateEvent ? <input type='hidden' name='event[state_event]' value={stateEvent} /> : null
        }
        {
          event.id ? <input type='hidden' name='_method' value='patch' /> : null
        }
        <div className="submit-buttons">
          <Button
            color='primary'
            className='customer-notification'
            type='button'
            onClick={onSubmit}
          >
            { event.id ? 'Save changes' : 'Create' }
          </Button>
        </div>
      </Form>
    </Fragment>
  );
}
