import React from 'react';
import { FormGroup, Input, Label } from "reactstrap";
import AssetContainer from "./AssetContainer";

const ContainerOption = ({ container, nestingLevel=0 }) => (
  <React.Fragment>
    <option value={container.id}>
      { ''.padStart(nestingLevel * 2, '-') }
      { nestingLevel > 0 ? ' ' : ''}
      {container.attributes.name}
    </option>
    {
      container.relationships['child_containers'].map(
        cc => (
          <ContainerOption
            container={cc}
            key={cc.id}
            nestingLevel={nestingLevel + 1}
          />
        )
      )
    }
  </React.Fragment>
);

const ContainerSelection = ({ containers, onSelect, selected }) => {
  if (containers.length === 0) {
    return (
      <p>This component has no physical layouts defined.</p>
    )
  }

  const containersById = {};
  containers.forEach(
    container => {
      containersById[container.id] = container
    }
  );

  const topLevelContainers = containers.filter(
    ac => !ac.relationships['parent_container']
  );

  return (
    <FormGroup>
      <Label for='container-select'>View layout for:</Label>
      <Input
        id='container-select'
        onChange={e => onSelect(containersById[e.target.value])}
        type='select'
        value={containersById[selected] ? selected : ''}
      >
        <option
          disabled
          value=''
        >
          Select a layout...
        </option>
        {
          topLevelContainers.map(
            ac => (
              <ContainerOption
                container={ac}
                key={ac.id}
              />
            )
          )
        }
      </Input>
    </FormGroup>
  )
}

const Component = ({ component, preselect }) => {

  const [containerID, setContainerID] = React.useState(preselect);

  React.useEffect(
    () => {
      const handleHistoryChange = (event) => {
        if (event.state && event.state.containerID) {
          setContainerID(event.state.containerID);
        }
      };
      window.addEventListener('popstate', handleHistoryChange);

      return () => {
        window.removeEventListener('popstate', handleHistoryChange);
      }
    },
    []
  );

  const selectContainer = (container) => {
    let newUrl = `./${container.attributes.hashid}`;
    if (window.location.pathname.match(/layouts$/)) {
      newUrl = `${window.location.pathname}/${container.attributes.hashid}`;
    }
    window.history.pushState(
      { containerID: container.id },
      '',
      newUrl
    );
    setContainerID(container.id);
  }

  return (
    <div>
      <h3>Physical layouts for {component.attributes.name}</h3>

      <ContainerSelection
        containers={component.relationships['asset_containers'] || []}
        onSelect={selectContainer}
        selected={containerID}
      />

      {
        containerID !== '' ?
          <AssetContainer
            containerID={containerID}
            setActiveContainer={selectContainer}
          />
          : null
      }

    </div>
  );
};

export default Component;
