import React from 'react';
import classNames from 'classnames';

const POWER_STATES = ['on', 'off'];
const MAINTENANCE_STATES = ['under_maintenance', 'investigating_problem'];

const Asset = ({ asset, className }) => (
  <a
    className={classNames(
      'asset',
      {
        'text-muted': asset.decommissioned
      },
      powerClassname(asset.metadata['power_state']),
      maintenanceClassname(asset.metadata['maintenance_state']),
      className
    )}
    href={asset.link}
  >
    <span className={`fa fa-fw ${asset.decommissioned ? 'fa-ban' : asset.icon || 'fa-cube'} mr-1`}></span>
    { asset.name }
    <span
      className="metadata-icons"
    >
      {
        POWER_STATES.indexOf(asset.metadata['power_state']) > -1 ? (
          <span className={`fa fa-fw fa-power-off`}></span>
        ) : ''
      }
      {
        MAINTENANCE_STATES.indexOf(asset.metadata['maintenance_state']) > -1 ? (
          <span className={`fa fa-fw fa-wrench`}></span>
        ) : ''
      }
    </span>
  </a>
);

function powerClassname(power_state){
  if (power_state === undefined) {
    return '';
  }
  const prefix = 'powered-';
  return prefix + power_state;
};

function maintenanceClassname(maintenance_state){
  if (maintenance_state === undefined) {
    return '';
  }
  return maintenance_state;
};


export default Asset;
