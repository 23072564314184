import React, { useState } from 'react';

import classnames from 'classnames';
import { SelectionState } from "../constants";
import IndeterminateCheckbox from "./IndeterminateCheckbox";

const ClusterPartTreeEntry = (
  {
    hideDecommissioned,
    part: {
      id, name, icon, type, children, selectionState, selfSelected, selfPending, toggleSelectionState, decommissioned
    }
  }
) => {

  const ident = `${type}-${id}`;

  const hasChildren = children.length > 0;

  const selected = selectionState();

  const [ expanded, setExpanded ] = useState(!hasChildren || selected > 0);

  const classes = classnames(
    'cluster-part',
    {
      'pending': selfPending,
      'selected': selected === SelectionState.SELF_ONLY || selected === SelectionState.SELF_AND_CHILDREN,
      'text-muted': decommissioned
    }
  );

  const childrenShouldBePruned = !(expanded || selected > SelectionState.UNSELECTED);

  if (decommissioned && hideDecommissioned) {
    return null;
  } else {
    return (
      <li>
        {
          hasChildren ?
          <button
            aria-expanded={expanded}
            className={classnames('btn', 'btn-sm', 'btn-primary', 'btn-collapse', { 'collapsed': !expanded })}
            id={`${ident}-expand`}
            onClick={() => setExpanded(!expanded)}
            type='button'
          >
          </button>
            : null
        }
        <div className={classes}>
          <span
            className={`fa fa-li fa-fw ${icon}`}
            title={type}
          />
          <div className='form-check form-check-inline'>
            <label
              className='form-check-label'
              htmlFor={ident}
            >
              {name}
            </label>
            <IndeterminateCheckbox
              className='form-check-input'
              id={ident}
              name='associations[]'
              value={ident}
              checked={selected === SelectionState.SELF_AND_CHILDREN || selected === SelectionState.SELF_ONLY}
              indeterminate={selected === SelectionState.SOME_CHILDREN}
              onChange={toggleSelectionState}
            />
          </div>
        </div>
        {
          hasChildren && !childrenShouldBePruned ?
            <ul
              className={classnames('fa-ul', 'collapse', { 'show': expanded })}
              id={`${ident}-children`}
            >
              {
                children.map(
                  child => (
                    <ClusterPartTreeEntry
                      key={`${child.type}-${child.id}`}
                      hideDecommissioned={hideDecommissioned}
                      part={child}
                    />
                  )
                )
              }
            </ul>
            : null
        }
      </li>
    );
  }
};

export default ClusterPartTreeEntry;

