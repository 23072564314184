import React from 'react';
import { Collapse } from 'reactstrap';

const Container = ({ children, className, href, icon, title }) => {

  const [expanded, setExpanded] = React.useState(true);
  return (
    <div className={`${className} card`}>
      <div className='card-header d-flex'>
        <button
          aria-expanded={expanded}
          className='btn btn-sm btn-primary btn-collapse px-1 m-1'
          onClick={() => setExpanded(!expanded)}
          type='button'
        >
        </button>
        <a href={href}>
          <span className={`fa fa-fw ${icon} mx-1`}></span>
          { title }
        </a>
      </div>
      <Collapse isOpen={expanded}>
        <div className='card-body'>
          { children }
        </div>
      </Collapse>
    </div>
  );
};

export default Container;
