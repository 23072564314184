export const getAndDenormalise = (url) => {
  return new Promise(
    (resolve, reject) => {
      fetch(
        url,
        {
          credentials: 'include',
          headers: {
            'Accept': 'application/vnd.api+json'
          }
        }
      ).then(
        response => response.json().then(
          data => {
            resolve(denormaliseAPIResponse(data));
          }
        )
      ).catch(
        err => reject(err)
      );
    }
  )
}

const denormaliseAPIResponse = (response) => {
  const record = {
    ...response.data,
    relationships: {}
  };

  Object.entries(response.data.relationships).forEach(
    ([relationship, objects]) => {
      if (Array.isArray(objects.data)) {
        record.relationships[relationship] = objects.data.map(
          ac => {
            const included = response.included.find(
              i => i.type === ac.type && i.id === ac.id
            );

            if (included) {
              return denormaliseAPIResponse(
                {
                  data: included,
                  included: response.included
                }
              );
            }
            return ac;
          }
        );
      }
      else if (!objects.data) {
        record.relationships[relationship] = null;
      }
      else {
        record.relationships[relationship] = response.included.find(
          i => i.type === objects.data.type && i.id === objects.data.id
        ) || objects.data;
      }
    }
  );

  return record;
}
