import React from 'react';

import moment from 'moment-business-days';

import EventWindowForm from "./EventWindowForm";
import EventCalendar from "./EventCalendar";
import { boundWindowToEvent } from "../constraints";

const earliestStart = event => moment().businessAdd(event['expiry_range']);

const addNewWindow = (event, oldWindows, currentDisplayStart) => {

  let suggestedStart = earliestStart(event).nextBusinessDay().hour(9).minute(0);
  if ( suggestedStart < currentDisplayStart ) {
    suggestedStart = moment(currentDisplayStart).hour(9).minute(0);
  }
  const duration = moment.duration(event.duration * 1000);
  const earliestEnd = suggestedStart.clone().add(duration);

  return (
    [
      ...oldWindows,
      {
        start_time: suggestedStart,
        end_time: earliestEnd
      }
    ]
  );
};

export default ({ event, windows, setWindows }) => {

  let currentDisplayStart = null;

  const [highlightedIndex, setHighlightedIndex] = React.useState(null);

  const onWindowUpdate = idx => window => {
    const boundWindow = boundWindowToEvent(window, event);
    const myWindows = [...windows];
    if (idx >= 0) {
      myWindows.splice(idx, 1, boundWindow);
    }
    else {
      myWindows.push(boundWindow);
    }
    setWindows(myWindows);
  };

  const onWindowDelete = idx => () => {
    const myWindows = [...windows];
    myWindows.splice(idx, 1);
    setWindows(myWindows);
    setHighlightedIndex(null);
  };

  const onViewChange = (start, end) => {
    currentDisplayStart = start;
  };

  return (
    <React.Fragment>
      <label>
        Availability windows
        <small className="form-text text-muted mb-1">
          <span className='fa fa-fw fa-info-circle fa-2x fa-pull-left' />
          Click and drag on the calendar to specify all periods of time in which
          you are available for this event to take place. The recipient will be
          able to choose from the time windows you specify to schedule this
          event.
        </small>
      </label>
      <EventCalendar
        earliestStart={earliestStart(event)}
        event={event}
        highlightedIndex={highlightedIndex}
        onWindowClick={(idx) => setHighlightedIndex(idx === highlightedIndex ? null : idx)}
        onCreate={onWindowUpdate(-1)}
        onUpdate={onWindowUpdate}
        onViewChange={onViewChange}
        windows={windows}
      />
      <div id="event-windows-container">
        {
          windows.map(
            (w, idx) => (
              <EventWindowForm
                event={event}
                highlighted={highlightedIndex === idx}
                index={idx}
                key={idx}
                onDelete={onWindowDelete(idx)}
                onUpdate={onWindowUpdate(idx)}
                showDelete={windows.length > 1}
                window={w}
              />
            )
          )
        }
      </div>
      <button
        className="btn btn-primary btn-sm mt-2"
        onClick={() => setWindows(addNewWindow(event, windows, currentDisplayStart))}
        type="button"
      >
        <span className="fa fa-plus mr-1"></span>
        Add another
      </button>
    </React.Fragment>
  )
}
