import React from 'react';
import ReactDOM from 'react-dom';
import { AssetVisualiser, AssetContainerVisualiser } from "./components";

export const initAssetVisualiser = (target) => {
  if (target) {
    const data = JSON.parse(target.dataset.assets);
    ReactDOM.render(
      <AssetVisualiser data={data} />,
      target
    )
  }
};

export const initAssetContainerVisualiser = (target) => {
  if (target) {
    const componentID = target.dataset.componentId;
    const preselect = target.dataset.preselect;
    ReactDOM.render(
      <AssetContainerVisualiser
        componentID={componentID}
        preselect={preselect}
      />,
      target
    );
  }
}
