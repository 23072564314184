import React from 'react';
import Container from "./Container";
import Asset from "./Asset";
import classNames from "classnames";

const AssetGroup = ({ group, showDecommissioned }) => (
  <Container
    className={classNames(
      'asset-group',
      {
        'text-muted': group.decommissioned
      }
    )}
    href={group.link}
    icon={group.decommissioned ? 'fa-ban' : group.icon}
    title={group.name}
  >
    <div className='asset-listing'>
      {
        group.assets.filter(
          a => showDecommissioned || !a.decommissioned
        ).map(
          asset => (
            <Asset
              key={asset.id}
              asset={asset}
            />
          )
        )
      }
    </div>
  </Container>
);

export default AssetGroup;
