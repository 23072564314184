import React from 'react';
import {getAndDenormalise} from "../../utils";
import Loading from "../../Loading";
import RenderedAssetContainer from "./RenderedAssetContainer";

const ParentLink = ({ onClick, parent }) => (
  <a
    href='#'
    onClick={(e) => {e.preventDefault(); e.stopPropagation(); onClick(e);}}
    tabIndex={1}
  >
    <span className='fa fa-chevron-left mr-1'></span>
    Back to {parent.attributes.name}
  </a>
);

const AssetContainer = ({ containerID, setActiveContainer }) => {

  const [ container, setContainer ] = React.useState(null);
  const [ loading, setLoading ] = React.useState(false);
  const [ reverseView, setReverseView ] = React.useState(false);

  React.useEffect(
    () => {
      setLoading(true);
      getAndDenormalise(
        `/api/v2/asset-containers/${containerID}?include=assets,child_containers,parent_container,child_containers.assets,child_containers.child_containers`
      ).then(
        container => {
          setContainer(container);
          setLoading(false);
        }
      )
    },
    [containerID]
  );

  if (loading) {
    return <Loading />;
  }

  if (!container) {
    return null;
  }

  return (
    <div>
      {
        container.relationships.parent_container ?
          (
            <ParentLink
              onClick={() => setActiveContainer(container.relationships.parent_container)}
              parent={container.relationships.parent_container}
            />
          )
          : null
      }
      <div className='d-flex justify-content-between'>
        <h4>
          {
            reverseView ? 'Rear' : 'Front'
          } view
        </h4>
        <button
          className='btn btn-primary'
          onClick={() => setReverseView(!reverseView)}
          type='button'
        >
          <span className='fa fa-refresh'></span> Switch view
        </button>
      </div>
      <RenderedAssetContainer
        container={container}
        reverseView={reverseView}
        setActiveContainer={setActiveContainer}
      />
    </div>
  );
}

export default AssetContainer;
