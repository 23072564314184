import React from 'react';
import ReactDOM from 'react-dom';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css'

export default {
  show: (element, props={}) => {
    ReactDOM.render(
      React.createElement(Picker, props),
      element
    );
  },
  hide: (element) => ReactDOM.unmountComponentAtNode(element),
};
