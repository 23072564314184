import React from 'react';
import ReactDOM from 'react-dom';
import FaqSearch from './components/FaqSearch';

export const renderFaqSearch = () => {
  const target = document.querySelector('#faq-search');

  if (target) {
    const associations = JSON.parse(target.dataset.associations);
    const consultancyPossible = target.dataset.consultancyPossible;
    ReactDOM.render(<FaqSearch associations={associations} consultancyPossible={consultancyPossible}/>, target);
  }
};
