import React from 'react';

import FullCalendar from '@fullcalendar/react';
import moment from 'moment';

import bootstrapPlugin from "@fullcalendar/bootstrap";
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from "@fullcalendar/moment";
import timeGridPlugin from "@fullcalendar/timegrid";

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';
import '@fullcalendar/bootstrap/main.css';

const postProcessCalendar = (event, allEvents) =>  allEvents.filter(
  e => e.id !== event.id && e.eventID !== event.id
).map(
  e => ({
    ...e,
    popoverTitle: undefined,
    popoverContent: undefined,
    url: undefined,
    backgroundColor: '#CCCCCC'
  })
);

const mapTime = (time) => {
  if (time._isAMomentObject) {
    return time.format()
  }
  return time;
};

const mapWindow = highlightedIndex => (w, idx) => ({
  id: `window${idx}`,
  backgroundColor: highlightedIndex === idx ? '#3498db' : '#9fff9e',
  textColor: highlightedIndex === idx ? '#FFFFFF' : '#000000',
  start: mapTime(w['start_time']),
  end: mapTime(w['end_time']),
  title: `Availability window ${idx + 1}`,
  editable: true,
  windowIndex: idx
});

const fetchEventsForCalendar = (event) => (info, onSuccess, onFailure) => {
  const url = new URL('/calendar.json', window.location.origin);

  url.searchParams.append('from', info.startStr);
  url.searchParams.append('to', info.endStr);

  fetch(
    url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  ).then(
    (response) => {
      if (response.ok) {
        response.json().then(
          allEvents => onSuccess(
            postProcessCalendar(event, allEvents)
          )
        );
      }
      else {
        onFailure(response);
      }

    }
  ).catch(onFailure)
};


const EventCalendar = ({ earliestStart, event, highlightedIndex, onCreate, onUpdate, onViewChange, onWindowClick, windows }) => {

  const calendarRef = React.createRef();

  const handleUpdate = ({ event }) => {
    const windowIndex = event.extendedProps.windowIndex;
    onUpdate(windowIndex)({
      start_time: event.start,
      end_time: event.end,
    });
  };

  const handleCreate = ({ startStr, endStr }) => {
    calendarRef.current.getApi().unselect(); // https://github.com/fullcalendar/fullcalendar/issues/5266
    onCreate({
      start_time: startStr,
      end_time: endStr
    });
  };

  // https://github.com/fullcalendar/fullcalendar-react/issues/46
  const fixInitialRenderBug = React.useEffect(
    () => {
      setTimeout(
        () => {
          const api = calendarRef.current.getApi();
          api.updateSize();
          api.scrollToTime('08:30:00');
        },
        100
      )
    },
    []
  );

  return (
    <FullCalendar
      datesRender={(arg) => onViewChange(arg.view.activeStart, arg.view.activeEnd)}
      defaultView='timeGridWeek'
      eventBorderColor='black'
      eventClick={({ event }) => onWindowClick(event.extendedProps.windowIndex)}
      eventDrop={handleUpdate}
      eventResize={handleUpdate}
      eventSources={[
        {
          events: fetchEventsForCalendar(event)
        },
        {
          events: windows.map(mapWindow(highlightedIndex))
        }
      ]}
      firstDay={1}
      height={500}
      plugins={[ bootstrapPlugin, interactionPlugin, momentPlugin, timeGridPlugin]}
      ref={calendarRef}
      select={handleCreate}
      selectable
      selectAllow={({ start }) => moment(start).isSameOrAfter(earliestStart)}
      validRange={{ start: earliestStart.clone().subtract(1, 'day').format('YYYY-MM-DD') }}
      views={{
        timeGridWeek: {
          allDaySlot: false,
          columnHeaderFormat: 'ddd Do',
          nowIndicator: true,
          scrollTime: '08:30:00'
        }
      }}
    />
  );
};

export default EventCalendar;
