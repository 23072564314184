import React from 'react';

export default ({ onChange, options, siteID, value }) => {

  const editor = React.createRef();

  React.useEffect(
    () => {
      // I am very sorry for using jQuery within React. Please forgive me.
      // But it does somewhat neatly tie in to the existing Md preview system.
      window.configureEditor($(editor.current));
    },
    []
  );

  return (
    <div className="markdown-field" data-markdown-editor="true" ref={editor}>
      <div className="markdown-edit">
        <div className="m-0">
          <div className="form-group emoji-container mb-0">
            <span className="emoji-button">
              <i className="fa fa-smile-o"></i>
            </span>
            <textarea
              className='form-control'
              data-markdown-content={true}
              data-site-id={siteID}
              onChange={onChange}
              value={value}
              {...options}
            />
          </div>
        </div>
      </div>
      <div className="markdown markdown-preview" data-markdown-preview="true">

      </div>
      <small className=' text-muted text-right mb-0 mt-1 d-block'>
        <span className="markdown-edit">
          Styling with Markdown is supported
        </span>
        <span className="markdown-preview">
          Showing Markdown preview
        </span>
        <button
          className="btn btn-primary btn-sm ml-1 markdown-preview"
          data-markdown-write-button="true"
          role="button"
          type="button"
        >
          Write
        </button>
        <button
          className="btn btn-primary btn-sm ml-1 markdown-edit"
          data-markdown-preview-button="true"
          role="button"
          type="button"
        >
          Preview
        </button>
      </small>
    </div>
  )
}
