import React from 'react';

export default ({ model }) => (
  <span className='related-links'>
    <span className="display-id-wrapper">
      <span className="fa fa-fw fa-institution mr-2" />
      <span className="display-id">
        {model['shortcode']}
      </span>
    </span>
    <span className="subject">
      {model['name']}
    </span>
  </span>
);
