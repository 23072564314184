import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import { DelaySpinner } from 'flight-reactware';
import { auth, showLoginForm, store as authStore } from 'flight-account-menu';

import ErrorBoundary from './ErrorBoundary';
import Script from './Script';

function getAuthToken() {
  return auth.selectors.ssoToken(authStore.getState());
}
function getCurrentUser() {
  return auth.selectors.currentUserSelector(authStore.getState());
}


const FlightStore = lazy(
  () => import(/*webpackChunkName: "flight-store" */ 'flight-store-lib')
);

document.addEventListener('DOMContentLoaded', () => {
  const root = document.querySelector('#flight-store');
  if (root != null) {
    const dataset = root.dataset;
    ReactDOM.render(
      <ErrorBoundary>
        <Suspense fallback={<DelaySpinner size="large" />}>
          <Script src="https://js.stripe.com/v3/">
            <FlightStore
              {...dataset}
              // Authentication dependencies.
              getAuthToken={ getAuthToken }
              getCurrentUser={ getCurrentUser }
              showLoginForm={ showLoginForm }
            />
          </Script>
        </Suspense>
      </ErrorBoundary>,
      root,
    );
  }
});
