import React from 'react';

const RailsFormMeta = () => {
  return (
    <React.Fragment>
      <input
        name="utf8"
        type="hidden"
        value="✓"
      />
      <input
        type="hidden"
        name="authenticity_token"
        value={window._authenticity_token}
      />
    </React.Fragment>
  )
};

export default RailsFormMeta;
