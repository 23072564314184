/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'core-js/stable';

import 'src/flight-store';
import emojiMart from 'src/emoji-mart';
import 'src/listGroupSelect';
import 'src/newCaseDetails';
import { initAccountMenu } from "../src/accountMenu";
import { initClusterTrees } from 'src/cluster-tree';
import { initCalendar } from 'src/fullcalendar';
import {renderOmnisearch} from "../src/omnisearch";
import {initAssetVisualiser, initAssetContainerVisualiser} from "../src/asset-vis";
import {initEventForm} from "../src/events";
import {renderFaqSearch} from "../src/faqsearch";

// The deprecated styles from reactware can be imported by uncommenting these
// lines.
// import 'flight-reactware/styles/bootstrap-theme.css';
// import 'flight-reactware/styles/flight.css';
// import 'flight-reactware/styles/fonts.css';

document.addEventListener('DOMContentLoaded', () => {
  initAccountMenu();
  renderOmnisearch();
  renderFaqSearch();
  initClusterTrees();
  initCalendar();
  initAssetVisualiser(document.getElementById('assetVisualiser'));
  initAssetContainerVisualiser(document.getElementById('assetContainerVisualiser'));
  initEventForm();
});

// Export various things via `window` so that our javascript in
// `app/assets/javascript` can reference it.

window.emojiMart = emojiMart;
