import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

const MetadataElement = ({ event, index, metadata }) => {
  const fieldID = `field_${index}`;
  const fieldName = `fields[${index}]`;

  return (
    <div className='additional-info'>
      <Label for={fieldID}>
        {metadata.optional ? '' : '*'}
        {metadata.name}
      </Label>
      <Input
        defaultValue={(event.metadata || {})[metadata.name] || ''}
        name={fieldName}
        required={!metadata.optional}
        type={metadata.type}
      />
      {
        !!metadata.help ?
          <small className='form-text text-muted'>
            { metadata.help }
          </small>
          : null
      }
    </div>
  )
};

const MetadataForm = ({ event, spec }) => {
  if (spec.length === 0) {
    return null;
  }
  return (
    <FormGroup>
      <Label>
        Additional information
      </Label>
      <div className='additional-info-container'>
        {
          spec.map(
            (md, idx) => (
              <MetadataElement
                event={event}
                index={idx}
                key={idx}
                metadata={md}
              />
            )
          )
        }
      </div>
    </FormGroup>
  );
};

export default MetadataForm;
