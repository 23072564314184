import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import Asset from "./Asset";

const calculateColumnBound = (bound) => ({ parent, reverseView, ...props }) => {

  const attributes = props.asset ? props.asset : props.container.attributes;

  if (parent && reverseView) {
    const inverseBound = bound === 'start' ? 'end' : 'start';
    return 1 + parent.attributes['x_capacity'] - (attributes[`x_${inverseBound}_position`] || 0);
  }
  else {
    return 1 + (attributes[`x_${bound}_position`] || 0);
  }
}

const Inner = styled.div`
  border: 1px solid black;
  
  min-height: ${ props => `${props.container.attributes['y_capacity'] * 2}rem` };
  margin: 0.5rem;
  
  display: grid;
  grid-template-columns: repeat(${ props => props.container.attributes['x_capacity']}, 1fr);
  grid-template-rows: auto repeat(${ props => props.container.attributes['y_capacity']}, 1fr);
  
  grid-row-start: ${ props => 2 + (props.container.attributes['y_start_position'] || 0) };
  grid-row-end: ${ props => 2 + (props.container.attributes['y_end_position'] || 0) };
  grid-column-start: ${ calculateColumnBound('start') };
  grid-column-end: ${ calculateColumnBound('end') };

`;

Inner.propTypes = {
  container: PropTypes.object.isRequired
};

const Label = styled.div`
  background-color: #2C3E50;
  color: white;
  grid-row: 1;
  grid-column: 1 / span ${ props => props.columnSpan };
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
`;

const RenderedAsset = styled(Asset)`
  grid-row-start: ${ props => 2 + (props.asset['y_start_position'] || 0) };
  grid-row-end: ${ props => 2 + (props.asset['y_end_position'] || 0) };
  grid-column-start: ${ calculateColumnBound ('start') };
  grid-column-end: ${ calculateColumnBound( 'end') };
`;

const Blurry = styled.div`
  filter: blur(1px);
`;

const AnonymousAsset = ({ onClick }) => (
  <Blurry
    className='asset'
    onClick={onClick}
    title='This asset is not visible at this zoom level. Select the parent container to view.'
  >
    <span className='fa fa-fw fa-cube mr-1' />
    ---
  </Blurry>
);

const AnonymousContainer = ({ onClick }) => (
  <Blurry
    onClick={onClick}
    title='This container is not visible at this zoom level. Select this container to view.'
  >
    <Inner container={{attributes: {x_capacity: 1, y_capacity: 1}}}>
      <Label>---</Label>
    </Inner>
  </Blurry>
)

const RenderedAssetContainer = ({ container, parent, reverseView, setActiveContainer }) => {

  return (
    <Inner
      container={container}
      parent={parent}
      reverseView={reverseView}
    >
      <Label
        onClick={(e) => {setActiveContainer(container); e.stopPropagation(); e.preventDefault();}}
        columnSpan={container.attributes['x_capacity']}
      >
        {container.attributes.name}
      </Label>
      {
        container.relationships.child_containers.map(
          cc => (
            cc.attributes ? (
            <RenderedAssetContainer
              container={cc}
              key={cc.id}
              parent={container}
              reverseView={reverseView}
              setActiveContainer={setActiveContainer}
            />
            ) : (
              <AnonymousContainer
                key={cc.id}
                onClick={() => setActiveContainer(container)}
              />
            )
          )
        )
      }
      {
        container.relationships.assets.map(
          a => (
            !!a.attributes ? (
              <RenderedAsset
                asset={a.attributes}
                key={a.id}
                parent={container}
                reverseView={reverseView}
              />
            )
            : (
              <AnonymousAsset
                key={a.id}
                onClick={() => setActiveContainer(container)}
              />
            )
          )
        )
      }
    </Inner>
  )
}

export default RenderedAssetContainer;
