import React from 'react';

import ClusterPart from "./ClusterPart";

export default ({ component, hideDecommissioned, selected, pending }) => {

  return (
    <ClusterPart
      part={component}
      hideDecommissioned={hideDecommissioned}
      selected={selected}
      pending={pending}
    />
  );
}
