import React, { useEffect, useState } from 'react';

// Load script at given `src`, execute children *after* script has been
// successfully loaded.
//
// This class interacts with React.Suspense to avoid rendering its children
// until the given script has been successfully loaded.  E.g.,
//
//   <React.Suspense fallback={<p>Waiting for script to load...</p>}>
//     <Script src="some/url">
//       <AComponent
//         that="requires the script at some/url to be loaded before it can be rendered"
//        />
//     </Script>
//   </React.Suspense>
//
class Script extends React.Component {
  static cache = new Map();

  static wrapPromise(promise) {
    let status = "pending";
    let result;
    let suspender = promise.then(
      r => {
        status = "success";
        result = r;
      },
      e => {
        status = "error";
        result = e;
      }
    );
    return {
      read() {
        if (status === "pending") {
          throw suspender;
        } else if (status === "error") {
          throw result;
        } else if (status === "success") {
          return result;
        }
      }
    };
  }

  constructor(props) {
    super(props);
    let suspender = Script.cache.get(props.src);
    if ( suspender == null ) {
      suspender = Script.wrapPromise(new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = props.src;
        script.onload = () => resolve(script);
        script.onerror = reject;
        document.body.appendChild(script);
      }));
      Script.cache.set(props.src, suspender);
    }
  }

  render() {
    const suspender = Script.cache.get(this.props.src);
    suspender.read();

    if (typeof this.props.children === 'function') {
      return this.props.children();
    }
    return this.props.children;
  }
};

export default Script;
