import React from 'react';

export default ({page, totalPages, fetchSuggestions}) => {

  const renderPrevious = () => {
    if(page === 1) return null;

    return (
      <li className="page-item">
        <a className="page-link"
           href="#"
           onClick={(event) => fetchSuggestions(event, page - 1, true)}
        >
          <span aria-hidden="true">&laquo;</span>
          <span className="sr-only">Previous</span>
        </a>
      </li>
    );
  };

  const renderNext = () => {
    if(page === totalPages) return null;

    return (
      <li className="page-item">
        <a className="page-link"
           href="#"
           onClick={(event) => fetchSuggestions(event, page + 1, true)}
        >
          <span aria-hidden="true">&raquo;</span>
          <span className="sr-only">Next</span>
        </a>
      </li>
    );
  };

  return (
    <div id="faq-result-pages">
      <nav aria-label="FAQ page navigation">
        <ul className="pagination justify-content-center">
          { renderPrevious() }
            {
              [...Array(totalPages)].map((value, index) =>
                <li className={`page-item${page === index + 1 ? ' active': ''}`} key={`page-${index + 1}`}>
                  <a className="page-link" href='#'
                     onClick={(event) => page !== index + 1
                       ? fetchSuggestions(event, index + 1, true)
                       : event.preventDefault()}
                  >
                    {index + 1}
                  </a>
                </li>
              )
            }
          { renderNext() }
        </ul>
      </nav>
    </div>
  );
}
