import React from 'react';

const STATE_ICONS = {
  'queued': 'clock-o',
  'open': 'circle-o',
  'resolved': 'check-circle-o',
  'verified': 'check-circle',
  'closed': 'lock',
  'notice': 'info-circle',
  'rejected': 'times-circle'
};

const CaseStateBadge = ({ state }) => {

  const icon = STATE_ICONS[state];

  return (
    <span
      className={`state-badge state-badge--${state} case-state-badge`}
    >
      { icon ? <span className={`fa fa-fw fa-${icon}`} /> : null }
      <span className="state-badge-text">
        {state}
      </span>
    </span>
  );
};

export default ({ model }) => (
  <span className='related-links'>
    <span className="display-id-wrapper">
      <span className="fa fa-fw fa-clipboard mr-2" />
      <span className="display-id">
        {model['display_id']}
      </span>
      <CaseStateBadge state={model['state']} />
    </span>
    <span className="subject">
      {model['subject']}
    </span>
  </span>
);
