import React from 'react';

const Loading = () => (
  <div>
    <span className='fa fa-fw fa-2x fa-spin fa-spinner'></span>
    Loading...
  </div>
);

export default Loading;
