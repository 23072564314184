import React, { Fragment } from 'react';

import Component from "./Component";
import { LastCheckedProvider } from '../LastCheckedProvider';

export default ({ components, hideDecommissioned, selected, pending }) => (
  <Fragment>
    {
      components.map(
        component => (
          <Component
            key={component.id}
            component={component}
            hideDecommissioned={hideDecommissioned}
            selected={selected}
            pending={pending}
          />
        )
      )
    }
  </Fragment>
);
