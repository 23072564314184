import React from 'react';
import Container from "./Container";
import AssetGroup from "./AssetGroup";

const Category = ({ category, showDecommissioned }) => (
  <Container
    className='category'
    icon={category.icon}
    title={category.name}
  >
    {
      category.groups.filter(
        ag => showDecommissioned || !ag.decommissioned
      ).map(
        group => (
          <AssetGroup
            key={group.id}
            group={group}
            showDecommissioned={showDecommissioned}
          />
        )
      )
    }
  </Container>
);

export default Category;
