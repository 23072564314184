import React from 'react';
import ReactDOM from 'react-dom';
import { EventForm } from "./components";

export function initEventForm() {
  const target = document.getElementById('event-form-wrapper');
  if (target) {
    const data = target.dataset;
    ReactDOM.render(
      <EventForm
        actionName={data.actionName}
        availableTypes={JSON.parse(data.availableTypes)}
        availableCases={JSON.parse(data.availableCases)}
        event={JSON.parse(data.event)}
        relatedCase={data.relatedCase}
        stateEvent={data.stateEvent}
        target={data.target}
        windows={JSON.parse(data.eventWindows)}
      />,
      target
    )
  }
}
