import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import momentPlugin from '@fullcalendar/moment';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';
import '@fullcalendar/bootstrap/main.css';

export function initCalendar() {
  const calendarEl = document.getElementById('calendar-container');
  if (!calendarEl) return;
  const eventsUrl = calendarEl.dataset.calendarFeed || '/engineering/calendar_json';

  const calendar = new Calendar(calendarEl, {
    events: eventsUrl,
    plugins: [ dayGridPlugin, bootstrapPlugin, listPlugin, timeGridPlugin, momentPlugin],
    header: {
      left: 'prevYear,prev,next,nextYear today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,listWeek'
    },
    height: 'parent',
    firstDay: 1, // Monday
    eventBorderColor: 'black',
    eventRender: function(info) {
      $(info.el).popover({
        html: true,
        title: info.event.extendedProps.popoverTitle,
        content: info.event.extendedProps.popoverContent,
        trigger: "hover",
        placement: "top",
        container: "body"
      });
      info.el.dataset.test = info.event.id;
    },
    views: {
      timeGridWeek: {
        allDaySlot: false,
        columnHeaderFormat: 'ddd Do',
        nowIndicator: true,
        scrollTime: '08:30:00'
      }
    }
  });

  calendar.render();
}
