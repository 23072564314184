import React from 'react';

export default ({ model }) => {
  const hasShortcode = !!model['shortcode'];
  const hasParent = !!model['parent'];
  return (
    <span className='related-links'>
    <span className="display-id-wrapper">
      <span className={`fa fa-fw fa-${model['icon']} mr-2`} />
      <span className="display-id">
        {hasShortcode ? model['shortcode'] : model['name']}
      </span>
      <div className={`support-type-badge ${model['support']['class']}`}>
        {
          model['support']['icon'] ? <span className={`fa fa-fw fa-${model['support']['icon']}`} /> : null
        }
        {
          model['support']['type']
        }
      </div>
    </span>
    <span className="subject">
      {
        hasShortcode ?
          model['namespaced_name']
          : hasParent ?
            model['parent']['name']
            : model['site']['name']
      }
    </span>
  </span>
  );
}
