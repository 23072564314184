import moment from "moment";

export const boundWindowToEvent = (window, event) => {
  const clonedWindow = {...window};
  const earliestStart = moment().businessAdd(event.expiry_range, 'day');

  const parsedStart = moment(window['start_time']);
  const parsedEnd = moment(window['end_time']);

  const newStart = moment.max(parsedStart, earliestStart);
  clonedWindow['start_time'] = newStart.format();

  const earliestEnd = newStart.clone().add(event.duration, 'seconds');
  clonedWindow['end_time'] = moment.max(parsedEnd, earliestEnd).format();

  return clonedWindow;
};

export const boundWindowsToEvent = (windows, event) => windows.map(w => boundWindowToEvent(w, event));
