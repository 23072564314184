import React, { Fragment, useState } from 'react';
import classnames from 'classnames';
import { SelectionState } from '../constants';

const ResultPart = ({ hideDecommissioned, part }) => {
  const selectionState = part.selectionState();

  const [ isExpanded, setExpanded ] = useState(true);

  const classes = classnames({
    'text-muted': !part.selfSelected,
    'pending': part.selfPending
  });

  const relevantChildren = part.children.filter(
    c => c.selectionState() !== SelectionState.UNSELECTED
  );

  const ulClasses = classnames(
    'fa-ul',
    'collapse',
    {
      'show': isExpanded
    }
  );

  if (part.decommissioned && hideDecommissioned) {
    return null;
  } else {
    return (
      <li>
        {
          relevantChildren.length > 0 ?
            <button
              aria-expanded={isExpanded}
              className={classnames('btn', 'btn-sm', 'btn-secondary', 'btn-collapse', { 'collapsed': !isExpanded })}
              onMouseUp={() => setExpanded(!isExpanded)}
              type='button'
            >
            </button>
            : null
        }
        <span className={classes}>
          <span className={`fa fa-li fa-fw ${part.icon}`} />
          { part.name }
        </span>
        <ul className={ulClasses}>
          {
            relevantChildren.map(
              c => <ResultPart key={`${c.type}-${c.id}`} part={c} hideDecommissioned={hideDecommissioned}/>
            )
          }
        </ul>
      </li>
    );
  }
};

export default ({ selections, hideDecommissioned }) => selections.length > 0 ? (
  <Fragment>
    {
      selections.map(
        part => <ResultPart
                  key={`${part.type}-${part.id}`}
                  part={part}
                  hideDecommissioned={hideDecommissioned}
                />
      )
    }
  </Fragment>
) : <li><em>None</em></li>;
