import React from 'react';
import {useEffect} from 'react';
import FaqItemCard from "./FaqItemCard";
import PaginationControls from "./PaginationControls";

const FaqSearch = (props) => {
  const [suggestions, setSuggestions] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [queryString, setQueryString] = React.useState('');
  const [originalQueryString, setOriginalQueryString] = React.useState(queryString);
  const [successfulQuery, setSuccessfulQuery] = React.useState(false);
  const [counts, setCounts] = React.useState({total: 0, pages: 0, existing_count: 0});
  const [page, setPage] = React.useState(1);
  const associationParams = Object.keys(props.associations).length > 0 ? `?${$.param(props.associations)}` : '';
  const consultancyPossible = props.consultancyPossible;
  const queryInput = document.getElementById("issue-search-query");
  const faqChooseTeamHelper = document.getElementById('faq-choose-team-helper');

  const scrollToTop = () => {
    const offset = 180;
    const elementPosition = queryInput.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  const fetchSuggestions = (event, selectedPage = page, pagination = false) => {
    if (event != null) event.preventDefault();
    if (!queryString || isLoading) {
      return;
    }
    setLoading(true);
    setSuggestions([]);
    setPage(selectedPage);
    let searchString = queryString;
    if (pagination) {
      scrollToTop();
      searchString = originalQueryString;
    } else {
      setOriginalQueryString(queryString);
    }
    $.ajax({
      url: 'faq-items/search',
      method: 'POST',
      data: {
        authenticity_token: window._authenticity_token,
        q: searchString,
        page: selectedPage,
      },
    }).done(function (content) {
      setSuccessfulQuery(true);
      setLoading(false);
      setCounts(content.counts);
      setSuggestions(content.results);
    }).fail(function (xhr) {
      setLoading(false);
      setSuccessfulQuery(false);
      alert(`Unable to get search results - ${xhr.status}: ${xhr.statusText}`);
    });
  };

  useEffect(() => {
    fetchSuggestions(null);
  }, []);

  const renderPaginationControls = () => {
    if (!isLoading && successfulQuery && counts.pages > 1) {
      return (
        <PaginationControls page={page}
                            totalPages={counts.pages}
                            fetchSuggestions={fetchSuggestions}/>
      )
    }
  };

  const displayFaqChooseTeamHelper = () => {
    if (isLoading) {
      faqChooseTeamHelper.style.display = "none";
    } else {
      faqChooseTeamHelper.style.display = "block";
    }
  }

  return (
    <div>
      <table className="table my-5 border-0">
        <tbody>
          <tr>
            <td className="faq-table-cell">
              <h4>Search for your issue or question by keyword...</h4>
            </td>
            <td className="faq-table-cell text-center">
              <h4>...Or drill down by topic</h4>
            </td>
          </tr>
          <tr>
            <td className="faq-table-cell bottom">
              <form onSubmit={(event) => fetchSuggestions(event, 1)}
                    id="faq-search-form" className="faq-search-element"
              >
                <div className="input-group faq-search-element">
                  <input type="text"
                         id="issue-search-query"
                         className="form-control faq-search-element"
                         style={{fontSize: 1.25 + 'rem'}}
                         name="q"
                         placeholder="I want to..."
                         autoComplete="off"
                         onChange={(event) => setQueryString(event.target.value)}
                  />
                  <div className="input-group-append">
                    <button className="btn btn-info btn-sm faq-search-element"
                            type="button"
                            id="faq-search-button"
                            disabled={isLoading}
                            onClick={(event) => fetchSuggestions(event, 1)}
                    >
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </form>
            </td>
            <td className="faq-table-cell bottom text-center">
              <a className="btn btn-primary btn-lg faq-search-element"
                 href={`team${associationParams}`}
              >
                <div className="faq-search-element" id="faq-button-contents">
                  <i className="fa fa-search mr-2" aria-hidden="true"></i>
                  <span>Search by topic</span>
                </div>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div id="issues-faq">
        <div className="d-flex justify-content-center mt-4">
          <div className={`spinner-border text-info ${isLoading === true ? '' : 'd-none'}`}
               role="status" id="loading-faq-items-spinner"
          >
            <span className="sr-only">Loading...</span>
          </div>
          <h5 id="no-faq-items" className={!isLoading && successfulQuery ? '' : 'd-none'}>
            {counts.total > 1 ? `${counts.existing_count + 1} - ${counts.existing_count + suggestions.length} of ` : ''}
            {counts.total === 0 ? "No" : `${counts.total}`} matching result
            {counts.total === 0 || counts.total > 1 ? "s" : ""}
          </h5>
        </div>
        <div id="faq-search-results">
          {suggestions.map(result =>
            <FaqItemCard model={result}
                         associations={associationParams}
                         consultancyPossible={consultancyPossible}
                         key={`faq-item-${result._id}`}/>
          )}
        </div>
      </div>
      {renderPaginationControls()}
      {displayFaqChooseTeamHelper()}
    </div>
  );
};

export default FaqSearch;
