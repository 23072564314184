import { SelectionState } from "./constants";

export class ClusterPart {
  constructor(spec, parent) {
    this.id = spec.id;
    this.name = spec.name;
    this.icon = spec.icon;
    this.type = spec.type;
    this.selfSelected = spec.selected || false;
    this.supportType = spec.supportType;
    this.selfPending = spec.pending;
    this.decommissioned = spec.decommissioned || false;

    this.children = [];

    this.parent = parent;

    this.anyChildrenSelected = this.anyChildrenSelected.bind(this);
    this.selfAndAllChildrenSelected = this.selfAndAllChildrenSelected.bind(this);
    this.selectionState = this.selectionState.bind(this);
    this.toggleSelectionState = this.toggleSelectionState.bind(this);
    this.onSelectionStateChange = this.onSelectionStateChange.bind(this);
  }

  anyChildrenSelected() {
    return this.children.some(
      c => c.selfSelected || (c.anyChildrenSelected && c.anyChildrenSelected())
    );
  }

  selfAndAllChildrenSelected() {
    if (this.children.length === 0) {
      return this.selfSelected;
    }
    return this.selfSelected && this.children.every(
      c => c.selfAndAllChildrenSelected()
    );
  }

  setAllSelected(selected, ignoreHandler=false) {
    this.children.forEach(c => c.setAllSelected(selected, true));
    this.selfSelected = selected;
    if (!ignoreHandler) {
      this.onSelectionStateChange();
    }
  }

  selectionState() {
    const anyChildren = this.anyChildrenSelected();
    if (this.selfSelected) {
      if (anyChildren) {
        return SelectionState.SELF_AND_CHILDREN;
      }
      return SelectionState.SELF_ONLY;
    }
    else if (anyChildren) {
      return SelectionState.SOME_CHILDREN;
    }
    return SelectionState.UNSELECTED;
  }

  toggleSelectionState() {
    this.selfSelected = !this.selfSelected;
    this.onSelectionStateChange();
  }

  onSelectionStateChange() {
    if (this.parent && this.parent.onSelectionStateChange) {
      this.parent.onSelectionStateChange()
    }
  }
}

class Asset extends ClusterPart {
}

class AssetGroup extends ClusterPart {
  constructor(spec, parent) {
    super(spec, parent);
    this.children = spec.assets.map(a => new Asset(a, this));
  }

  toggleSelectionState() {
    const current = this.selectionState();

    switch (current) {
      case SelectionState.UNSELECTED:
      case SelectionState.SOME_CHILDREN:
        this.selfSelected = true;
        break;
      case SelectionState.SELF_ONLY:
        this.setAllSelected(this.children.length > 0, true);
        break;
      case SelectionState.SELF_AND_CHILDREN:
        this.setAllSelected(!this.selfAndAllChildrenSelected(), true);
        break;
    }

    this.onSelectionStateChange();

  }
}

export class Component extends ClusterPart {
  constructor(spec, parent) {
    super(spec, parent);

    const componentChildren = spec.components.map(c => new Component(c, this));
    const assetGroupChildren = spec.assetGroups.map(agc => new AssetGroupCategory(agc, this));
    const ungrouped = spec.assets.map(ug => new Asset(ug, this));
    const groupsAndUngrouped = ungrouped.concat(assetGroupChildren);

    if (componentChildren.length > 0) {
      this.children.push(
        new Pseudogroup({
          id: this.id,
          type: 'allcomponents',
          name: 'Components',
          icon: 'fa-gears',
          children: componentChildren
        }, this)
      );
    }

    if (groupsAndUngrouped.length > 0) {
      this.children.push(
        new Pseudogroup({
          id: this.id,
          type: 'allassets',
          name: 'Assets',
          icon: 'fa-cubes',
          children: groupsAndUngrouped
        }, this)
      )
    }
  }

  toggleSelectionState() {
    const current = this.selectionState();

    switch (current) {
      case SelectionState.UNSELECTED:
      case SelectionState.SOME_CHILDREN:
        this.selfSelected = true;
        break;
      case SelectionState.SELF_ONLY:
        // If we have no children, just do a simple toggle
        this.setAllSelected(this.children.length > 0, true);
        break;
      case SelectionState.SELF_AND_CHILDREN:
        const allChildren = this.selfAndAllChildrenSelected();
        this.setAllSelected(!allChildren, true);
        break;
    }

    this.onSelectionStateChange();
  }
}

export class Pseudogroup extends ClusterPart {
  constructor(spec, parent) {
    super(spec, parent);
    this.children = spec.children;
  }

  setAllSelected(selected, ignoreHandler=false) {
    // We never want a pseudogroup to mark itself selected
    this.children.forEach(c => c.setAllSelected(selected, true));
    if (!ignoreHandler) {
      this.onSelectionStateChange();
    }
  }

  selfAndAllChildrenSelected() {
    if (this.children.length === 0) {
      return true;
    }
    return this.children.every(
      c => c.selfAndAllChildrenSelected()
    );
  }

  selectionState() {
    const selectedChildren = this.children.filter(c => c.selectionState() > SelectionState.UNSELECTED);
    const minChildSelectionState = selectedChildren.length > 0 ?
      Math.min(...selectedChildren.map(c => c.selectionState())) :
      SelectionState.UNSELECTED;

    if (minChildSelectionState >= SelectionState.SELF_ONLY && selectedChildren.length === this.children.length) {
      return SelectionState.SELF_AND_CHILDREN;
    }
    else if (selectedChildren.length > 0) {
      return SelectionState.SOME_CHILDREN;
    }
    return SelectionState.UNSELECTED;
  }

  toggleSelectionState() {
    const current = this.selectionState();
    switch (current) {
      case SelectionState.UNSELECTED:
      case SelectionState.SELF_ONLY:
      case SelectionState.SOME_CHILDREN:
        this.setAllSelected(true, true);
        this.selfSelected = false;  // We never want a pseudogroup to consider itself selected

        break;
      case SelectionState.SELF_AND_CHILDREN:
        this.setAllSelected(false, true);
        break;
    }

    this.onSelectionStateChange();
  }
}

class AssetGroupCategory extends Pseudogroup {
  constructor(spec, parent) {
    super(spec, parent);
    this.children = spec.groups.map(ag => new AssetGroup(ag, this));
  }

  selectionState() {
    const selectedChildren = this.children.filter(c => c.selectionState() > SelectionState.UNSELECTED);
    const minChildSelectionState = selectedChildren.length > 0 ?
      Math.min(...selectedChildren.map(c => c.selectionState())) :
      SelectionState.UNSELECTED;

    if (minChildSelectionState > SelectionState.SELF_ONLY && selectedChildren.length === this.children.length) {
      return SelectionState.SELF_AND_CHILDREN;
    }
    else if (selectedChildren.length > 0) {
      return SelectionState.SOME_CHILDREN;
    }
    return SelectionState.UNSELECTED;
  }

  toggleSelectionState() {
    const current = this.selectionState();
    switch (current) {
      case SelectionState.UNSELECTED:
      case SelectionState.SELF_ONLY:
        // Select only immediate children (e.g. asset groups)
        this.children.forEach(
          c => c.selfSelected = true
        );
        break;
      case SelectionState.SOME_CHILDREN:
        // Select all asset groups and assets
        this.setAllSelected(true, true);

        break;
      case SelectionState.SELF_AND_CHILDREN:
        // Select nothing
        this.setAllSelected(false, true);
        break;
    }
    this.selfSelected = false;  // We never want a pseudogroup to consider itself selected

    this.onSelectionStateChange();
  }
}
