import mkDebug from 'debug';
const debug = mkDebug('FC:NewCaseDetails');

// Wraps the details section on the new case page.
//
//  - Shows and hides the issue fields for the selected issue.
//  - Adjusts the comment as appropriate for the selected issue.
//  - Prevents submission of the form without any details.
//
class NewCaseDetails {
  constructor(form) {
    this.form = form;
    this.issueSelect = this.form.querySelector('[data-issue-field-select]');
    this.fieldGroupsByIssueId = this.buildFieldGroups();
    this.comment = new Comment(form);

    this.issueSelected = this.issueSelected.bind(this);
    this.preventSubmissionWithoutAnyDetails = this.preventSubmissionWithoutAnyDetails.bind(this);
    this.issueSelect.addEventListener('change', this.issueSelected);
    this.form.addEventListener('input', this.preventSubmissionWithoutAnyDetails);
    this.issueSelected();
  }

  removeEventListeners() {
    this.issueSelect.removeEventListener('change', this.issueSelected);
    this.form.removeEventListener('input', this.preventSubmissionWithoutAnyDetails);
  }

  buildFieldGroups() {
    const issueIds = [];
    this.issueSelect.querySelectorAll('option').forEach((option) => {
      if (option.value !== "") {
        issueIds.push(option.value);
      }
    });
    return issueIds.reduce(
      (accum, issueId) => {
        const fieldsForIssue = this.form.querySelectorAll(`[data-issue-field="${issueId}"]`);
        accum[issueId] = new IssueFieldGroup(issueId, fieldsForIssue);
        return accum;
      },
      {},
    );
  }

  issueSelected() {
    const selectedIssueId = this.issueSelect.selectedOptions[0].value;
    this.selectedFieldGroup = this.fieldGroupsByIssueId[selectedIssueId];
    debug("Selected issue %s.  Field groups %O", selectedIssueId, this.selectedFieldGroup);

    if (this.selectedFieldGroup) {
      debug(
        'Selected field group %s empty',
        this.selectedFieldGroup.isEmpty() ? 'IS' : 'IS NOT'
      );
      debug(
        'Selected field group %s mandatory fields',
        this.selectedFieldGroup.hasMandatoryFields() ? 'HAS' : 'DOES NOT HAVE'
      );
    }

    this.showFieldsForSelectedIssue();
    if (this.selectedFieldGroup == null || this.selectedFieldGroup.isEmpty()) {
      this.hideCaseDetails();
      this.comment.setRequired(true)
      this.comment.setTitle('Supporting information');
    } else if (this.selectedFieldGroup.hasMandatoryFields()) {
      this.showCaseDetails();
      this.comment.setRequired(false);
      this.comment.setTitle('Additional information');
    } else {
      // An issue with all optional fields.
      this.showCaseDetails();
      this.comment.setTitle('Additional information');
      this.preventSubmissionWithoutAnyDetails();
    }
  }

  hideCaseDetails() {
    this.form.querySelector('#case-details-label').style.display = 'none';
    this.form.querySelector('#case-details').style.display = 'none';
  }

  showCaseDetails() {
    this.form.querySelector('#case-details-label').style.display = '';
    this.form.querySelector('#case-details').style.display = '';
  }

  // The selected issue could have some fields which are all optional.  If
  // this is the case, the user must fill out at least one.  We ensure that is
  // the case by making them all required, until at least one is filled out.
  preventSubmissionWithoutAnyDetails() {
    if (this.selectedFieldGroup == null
      || this.selectedFieldGroup.isEmpty()
      || this.selectedFieldGroup.hasMandatoryFields()
    ) { return; }

    debug("Preventing submission without any details")
    if (this.comment.filledIn() || this.selectedFieldGroup.anyFieldFilledIn()) {
      debug("Some details provided. Removing required.")
      this.comment.setRequired(false);
      this.selectedFieldGroup.removeRequired();
    } else {
      debug("No details provided. Forcing required.")
      this.comment.setRequired(true);
      this.selectedFieldGroup.forceRequired();
    }
  }

  showFieldsForSelectedIssue() {
    Object.keys(this.fieldGroupsByIssueId).forEach((issueId) => {
      const fieldGroup = this.fieldGroupsByIssueId[issueId];
      fieldGroup.hide();
    });
    if (this.selectedFieldGroup != null) {
      this.selectedFieldGroup.show();
    }
  }
}

// Wraps the issue fields belonging to a single issue as shown on the new case
// page.
class IssueFieldGroup {
  constructor(issueId, fields) {
    this.issueId;
    this.fields = [];
    fields.forEach((field) => {
      this.fields.push(new IssueField(field));
    });
  }

  hide() {
    this.fields.forEach(field => field.hide());
    this.removeRequired();
  }

  show() {
    this.fields.forEach(field => field.show());
    this.fields.forEach(field => field.addRequired());
  }

  removeRequired() {
    this.fields.forEach(field => field.removeRequired());
  }

  forceRequired() {
    this.fields.forEach(field => field.forceRequired());
  }

  isEmpty() {
    return this.fields.length === 0;
  }

  hasMandatoryFields() {
    if (this.isEmpty()) {
      return false;
    }
    return this.fields.some(field => !field.isOptional());
  }

  anyFieldFilledIn() {
    return this.fields.some(field => field.filledIn());
  }
}

// Wraps a single issue field as shown on the new case page.
class IssueField {
  constructor(field) {
    this.domNode = field;
    this.input = this.domNode.querySelector('[data-issue-field-input]');
  }

  hide() {
    this.domNode.style.display = 'none';
  }

  show() {
    this.domNode.style.display = '';
  }

  // Remove the `required` attribute from the DOM node.
  removeRequired() {
    debug("Removing required for %O", this.input);
    this.input.required = false;
  }

  // Add the `required` attribute to the DOM node if this field is required
  // when its issue is selected.
  addRequired() {
    debug("Setting required for %O to %s", this.input, !this.isOptional());
    this.input.required = !this.isOptional();
  }

  forceRequired() {
    debug("Forcing required for %O", this.input);
    this.input.required = true;
  }

  isOptional() {
    return this.input.dataset.optional === "true";
  }

  filledIn() {
    return this.input.value.trim() !== ""
  }
}

class Comment {
  constructor(form) {
    this.heading = form.querySelector("[data-comment-label-heading]");
    this.textarea = form.querySelector('#case_details');
  }

  filledIn() {
    return this.textarea.value.trim() !== ""
  }

  setRequired(required) {
    debug("Set comment required to %s", required);
    this.textarea.required = required;
  }

  setTitle(title) {
    this.heading.innerText = title;
  }
}

let newCaseDetails;

document.addEventListener('DOMContentLoaded', () => {
  const form = document.querySelector('#new_case');
  if (form) {
    newCaseDetails = new NewCaseDetails(form);
    window.newCaseDetails = newCaseDetails;
  }
});
