import React from 'react';
import ReactDOM from 'react-dom';
import Omnisearch from './components/Omnisearch';

export const renderOmnisearch = () => {
  const target = document.querySelector('#omnisearch');

  if (target) {
    ReactDOM.render(
      <Omnisearch />,
      target
    )
  }
};
