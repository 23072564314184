import React from 'react';
import CaseSearchResult from './CaseSearchResult';
import SiteSearchResult from "./SiteSearchResult";
import ComponentSearchResult from "./ComponentSearchResult";

const ErrorResult = () => (
  <span className='text-danger'>
    Search is currently unavailable
  </span>
);

const RESULT_RENDERERS = {
  'cases': CaseSearchResult,
  'components': ComponentSearchResult,
  'error': ErrorResult,
  'sites': SiteSearchResult
};

const GenericResult = ({ model: { gid } }) => (
  <span>Unknown search result type with GID {gid}</span>
);

const SearchResult = ({ result }) => {
  const SpecificResult = RESULT_RENDERERS[result['_index']] || GenericResult;

  return <SpecificResult model={result['_source']} />
};

export default SearchResult;
