import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import Forest from "./components/Forest";
import ResultsTree from "./components/ResultsTree";

import { Component } from './model';

import { LastCheckedProvider } from './LastCheckedProvider';

const _extractSelectedChildrenInner = (acc, component) => {
  if (component.selfSelected) {
    acc.push(component);
  }
  return acc.concat(component.children.reduce(_extractSelectedChildrenInner, []))
};

// Recursively extracts only those components that are directly (e.g. self)
// selected.
const extractSelectedChildren = (components) => components.reduce(_extractSelectedChildrenInner, []);

class ClusterTree extends React.Component {
  constructor(props) {
    super(props);

    const { initialTree, decommissionedDefault, allSitesContext } = props;

    const componentList = initialTree.map(c => new Component(c, this));
    this.state = {
      components: componentList,
      hideDecommissioned: !decommissionedDefault,
      selections: componentList.filter(c => c.selectionState() > 0)
    };

    this.onSelectionStateChange = this.onSelectionStateChange.bind(this);
    this.dispatchEvent = this.dispatchEvent.bind(this);
    this.setHideDecommissioned = this.setHideDecommissioned.bind(this);
  }

  componentDidMount() {
    this.dispatchEvent();
  }

  onSelectionStateChange() {
    this.setState({
      selections: this.state.components.filter(c => c.selectionState() > 0)
    });
    this.dispatchEvent();
  }

  dispatchEvent() {
    const evt = new CustomEvent(
      'clusterTreeUpdate',
      {
        detail: {
          selectedItems: extractSelectedChildren(this.state.components)
        }
      });
    document.dispatchEvent(evt);
  }

  setHideDecommissioned(hide) {
    this.setState({ hideDecommissioned: hide });
  }

  render() {
    const { hideDecommissioned } = this.state;
    const { allowDecommissioned } = this.props;
    const { allSitesContext } = this.props
    const klass = allSitesContext === true ? "invisible-decommissioned-control" : "decommissioned-control"

    return (
      <Fragment>
        { allowDecommissioned &&
          ReactDOM.createPortal(
            <div className={klass}>
              <label className={'mr-1 align-middle'}>
                Show decommissioned assets
              </label>
              <input
                type='checkbox'
                checked={!hideDecommissioned}
                onChange={(e) => this.setHideDecommissioned(!e.target.checked)}
                className={'decommissioned-checkbox'}
              />
            </div>,
            document.getElementById('decommissioned-checkbox')
          )
        }
        <LastCheckedProvider>
          <Forest
            components={this.state.components}
            hideDecommissioned={hideDecommissioned}
          />
          {
            this.props.target ?
              ReactDOM.createPortal(
                <ResultsTree
                  selections={this.state.selections}
                  hideDecommissioned={hideDecommissioned}
                />,
                this.props.target
              ) : null
          }
        </LastCheckedProvider>
      </Fragment>
    );
  }
}


const createClusterTree = (rootElement, index) => {
  const initialTree = JSON.parse(rootElement.dataset.clusterTree);
  const target = rootElement.dataset.target ? document.querySelector(rootElement.dataset.target) : null;
  const allowDecommissioned = JSON.parse(rootElement.dataset.allowDecommissioned);
  const decommissionedDefault = rootElement.dataset.decommissionedDefault ? 
    JSON.parse(rootElement.dataset.decommissionedDefault) : false;
  const allSitesContext = rootElement.dataset.allSitesContext ? 
    JSON.parse(rootElement.dataset.allSitesContext) : false;

  ReactDOM.render(
      <ClusterTree
        allowDecommissioned={allowDecommissioned}
        initialTree={initialTree}
        target={target}
        decommissionedDefault={decommissionedDefault}
        allSitesContext={allSitesContext}
        key={`cluster-tree-${index}`}
      />,
      rootElement
  );
  rootElement.dataset.initialised = true;
};

const fixDropdownClusterTree = (_, tree) => {
  const dropdown = $(tree).closest('.dropdown');
  dropdown.on('hide.bs.dropdown', function(e) {
    if (e.clickEvent) {
      // If the original click event came from something inside the dropdown,
      // return false to cancel the hide and leave the dropdown showing.
      return !$.contains(dropdown[0], e.clickEvent.target);
    }
  })
};

export const initClusterTrees = () => {
  document.querySelectorAll('[data-cluster-tree]').forEach( (t, index) => createClusterTree(t, index));
  $('.dropdown-menu .cluster-tree').on('click', fixDropdownClusterTree);

  $("[data-load-content-from]").on("loadContent.loaded", (event, dropdown) => {
    dropdown.querySelectorAll('[data-cluster-tree]').forEach( (t, index) => createClusterTree(t, index));
    $('.dropdown-menu .cluster-tree').each(fixDropdownClusterTree)
  });
};
