import React from 'react';

import { Button, Label } from 'reactstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import classNames from 'classnames';

require('react-datetime/css/react-datetime.css');

const DATE_FORMAT = 'ddd Do MMM YYYY';
const TIME_FORMAT = 'HH:mm';

const EventWindowForm = ({ event, highlighted, index, onDelete, onUpdate, showDelete, window }) => {

  const updateWindow = (attrs) => onUpdate({ ...window, ...attrs });

  const idPrefix = `event_event_windows_${index}`;
  const startFieldID = `${idPrefix}_start_time`;
  const endFieldID = `${idPrefix}_end_time`;

  const parsedStart = moment(window['start_time']);
  const parsedEnd = moment(window['end_time']);

  const earliestStart = moment().businessAdd(event.expiry_range, 'day');
  const earliestEnd = parsedStart.clone().add(event.duration, 'seconds');

  // NB These methods are only used to determine which dates are enabled in the
  // picker, not to restrict time entry; we therefore want to allow from the
  // start of the earliest valid day.
  const isValidStartDate = (date) => date.isSameOrAfter(earliestStart.clone().startOf('day'));
  const isValidEndDate = (date) => date.isSameOrAfter(earliestEnd.clone().startOf('day'));

  return (
    <div className={classNames('form-inline', 'event-window', {'highlighted': highlighted})}>
      <span className='mr-1'>
        {index + 1}.
      </span>
      <Label for={startFieldID}>From</Label>
      <Datetime
        dateFormat={DATE_FORMAT}
        inputProps={{
          className: 'form-control mx-1',
          id: startFieldID,
          name: 'event[event_windows_attributes][][start_time]'
        }}
        isValidDate={isValidStartDate}
        onChange={(newDate) => newDate._isAMomentObject && updateWindow({ start_time: moment.max(earliestStart, newDate) })}
        timeFormat={TIME_FORMAT}
        value={parsedStart}
      />
      <Label for={endFieldID}>To</Label>
      <Datetime
        dateFormat={DATE_FORMAT}
        inputProps={{
          className: 'form-control mx-1',
          id: endFieldID,
          name: 'event[event_windows_attributes][][end_time]'
        }}
        isValidDate={isValidEndDate}
        onChange={(newDate) => newDate._isAMomentObject && updateWindow({ end_time: moment.max(earliestEnd, newDate) })}
        timeFormat={TIME_FORMAT}
        value={parsedEnd}
      />
      {
        showDelete ?
          (
            <Button
              color='danger'
              onClick={onDelete}
              size='sm'
            >
              <span className='fa fa-fw fa-trash'></span>
            </Button>
          )
          : null}
      {
        window.id ? <input type="hidden" name="event[event_windows_attributes][][id]" value={window.id} /> : null
      }
    </div>
  )
};

export default EventWindowForm;
