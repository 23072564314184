import React from 'react';

import { FormGroup, Input, Label } from 'reactstrap';

import Container from "./Container";
import Category from "./Category";
import AssetGroup from "./AssetGroup";

export default ({ data }) => {

  const [showDecommissioned, setShowDecommissioned] = React.useState(false);

  return (
    <React.Fragment>
      <FormGroup
        check
        className='mb-2 text-right'
      >
        <Label check>
          <Input
            type="checkbox"
            checked={showDecommissioned}
            onChange={(e) => setShowDecommissioned(e.target.checked)}
          />{' '}
          Show decommissioned assets
        </Label>
      </FormGroup>

      <Container
        href={data.link}
        icon={data.icon}
        title={data.name}
      >
        {
          (data.assets || []).length > 0 ?
            // These assets have no group. Let's create a fake one to hold them.
            <AssetGroup
              group={{
                assets: data.assets.filter(a => showDecommissioned || !a.decommissioned),
                name: 'Assets',
                icon: 'fa-cubes'
              }}
              showDecommissioned={showDecommissioned}
            />
            :
            null
        }
        {
          (data.assetGroups || []).map(
            (agc) => (
              <Category
                key={agc.id}
                category={agc}
                showDecommissioned={showDecommissioned}
              />
            )
          )
        }
      </Container>
    </React.Fragment>
  );
};
