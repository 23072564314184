document.addEventListener('DOMContentLoaded', () => {
  class SingleSelectionStrategy {
    constructor(form, inputName, list) {
      this.list = list;
      this.input = form.find(`[name="${inputName}"]`);
      if (!this.input.get(0)) {
        this.input = $('<input>').attr({
          type: 'hidden',
          name: inputName,
        }).appendTo(form);
      }
    }

    select(li) {
      this.removeAllHighlights();
      this.input.val(li.data('id'));
      li.addClass('active');
    }

    deselect(li) {
      this.removeAllHighlights();
      this.input.val('');
    }

    removeAllHighlights() {
      const items = this.list.find('li');
      items.removeClass('active');
    }
  }

  class MultipleSelectionStrategy {
    constructor(form, inputName) {
      this.form = form;
      this.inputName = inputName;
    }

    select(li) {
      $('<input>').attr({
        type: 'hidden',
        name: this.inputName,
        value: li.data('id'),
      }).appendTo(this.form);
      li.addClass('active');
    }

    deselect(li) {
      const hiddenTag = this.form.find(
        `[name="${this.inputName}"][value="${li.data('id')}"]`
      );
      li.removeClass('active');
      hiddenTag.remove();
    }
  }

  $("[data-list-group-select]").each(init);
  $("[data-load-content-from]").on("loadContent.loaded", (event, modal) => {
    $(modal).find("[data-list-group-select]").each(init);
  });

  function init() {
    const list = $(this);
    const inputName = list.data('list-group-select-input-name');
    const form = list.parents('form');
    const strategyFactory = list.data('list-group-select-multiple') ?
      MultipleSelectionStrategy :
      SingleSelectionStrategy;
    const selectionStrategy = new strategyFactory(form, inputName, list);

    const items = list.find('li');
    items.each((idx, li) => {
      li = $(li);
      li.on('click', (event) => {
        const unSetting = li.hasClass('active');
        if (unSetting) {
          selectionStrategy.deselect(li);
        } else {
          selectionStrategy.select(li);
        }
        event.preventDefault();
      });
    });
  };
});
